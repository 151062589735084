import { Routes, Route, useLocation } from "react-router-dom";

import Art from "../pages/Art";
import Me from "../pages/Me";
import People from "../pages/People";
import Wildlife from "../pages/Wildlife";

const AnimateRoutes = () => {
    
    return <Routes>
        {/* <Route path="/art" element={<Art />} /> */}
        <Route path="/" element={<Me />} />
        <Route path="/people" element={<People />} />
        <Route path="/wildlife" element={<Wildlife />} />
    </Routes>
}

export default AnimateRoutes;