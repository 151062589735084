interface Dictionary {
    [x: string]: string;
  };
  

export const people: Dictionary[] = [
  {full_size: "R6II0548.jpg", subject: "", location: ""},
  {full_size: "R6II0491.jpg", subject: "", location: ""},
  {full_size: "R6II0535_03.jpg", subject: "", location: ""},
  {full_size: "R6II0136_01.jpg", subject: "", location: ""},
  {full_size: "R6II0556.jpg",  subject: "", location: ""},
  {full_size: "R6II0338.jpg", subject: "", location: ""},
  {full_size: "R6II8973.jpg", subject: "", location: ""},
  // {full_size: "R6II0905.jpg", subject: "", location: ""},
]