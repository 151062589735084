interface Dictionary {
    [x: string]: string;
  };
  

export const wildlife: Dictionary[] = [
    // {full_size: "R6II5628_v3_small.jpg", subject: "SPERM WHALE", location: "Andenes, Norway"},
    // {full_size: "R6II8098_small.jpg", subject: "SEA EAGLE", location: "Trollfjord, Norway"},
    // {full_size: "R6II0720_small.jpg", subject: "ARCTIC TERN", location: "Liland, Norway"},
    // {full_size: "R6II4469_small.jpg", subject: "GREY HERON", location: "Liland, Norway"},
    // {full_size: "R6II9942_small.jpg",  subject: "ARCTIC TERN", location: "Liland, Norway"},
    {full_size: "R6II5628_v3_small.jpg", subject: "", location: ""},
    {full_size: "R6II3627.jpg",  subject: "", location: ""},
    {full_size: "R6II8098_small.jpg", subject: "", location: ""},
    {full_size: "R6II3233.jpg",  subject: "", location: ""},
    {full_size: "R6II0720_small.jpg", subject: "", location: ""},
    {full_size: "R6II6529_01.jpg",  subject: "", location: ""},
    {full_size: "R6II4469_small.jpg", subject: "", location: ""},
    {full_size: "R6II9942_small.jpg",  subject: "", location: ""},
]
