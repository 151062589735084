import {useState, useRef, useEffect } from "react";

import { CgMenuRight } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";

import { Link } from "react-router-dom";

import { motion } from "framer-motion";


const menuVariants = {
    hidden: {
        x: "100%",
        transition: {
            ease: [0.6, 0.01, -0.05, 0.9]
        }
    },
    show: {
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.9]
        }
    }
}

// /**
//  * This Hook can be used for detecting clicks outside the Opened Menu
//  */
// function useClickOutside(ref: any, onClickOutside: any) {
//     useEffect(() => {
//       /**
//        * Invoke Function onClick outside of element
//        */
//       function handleClickOutside(event: any) {
//         if (ref.current && !ref.current.contains(event.target)) {
//           onClickOutside();
//         }
//       }
//       // Bind
//       document.addEventListener("mousedown", handleClickOutside);
//       return () => {
//         // dispose
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, [ref, onClickOutside]);
//   }

const MobileNav = () => {
    const [openMenu, setOpenMenu] = useState(false);
    // const wrapperRef = useRef(null);
    // useClickOutside(wrapperRef, () => {
    //     setOpenMenu(false);
    // });

    return <nav className="visibile md:invisible">
    <div onClick={()=>setOpenMenu(true)} className="text-xl cursor-pointer">
        <CgMenuRight/>
    </div>

    <motion.div
    variants={menuVariants}
    initial="hidden"
    animate={openMenu ? "show" : ""}
    className="menu bg-white w-full absolute top-0 right-0 z-20">
        <div onClick={()=>setOpenMenu(false)} >
            <IoMdClose className="text-2xl absolute z-30 right-4 top-14 cursor-pointer"/>
        <ul>
            <li><Link to="/">ME</Link></li>
            <li><Link to="/people">PEOPLE</Link></li>
            <li><Link to="/wildlife">WILDLIFE</Link></li>
            {/* <li><Link to="/art">ART</Link></li> */}
        </ul>
        </div>
    </motion.div>
    </nav>;
};

export default MobileNav;