import React from 'react';

import MyPhoto from "../images/me_bw.jpg";

// const Me = () => {
//   return (
//     <div>
//         <div className="intro">
//             <img src={MeCropped} alt="Me in Norway" height="auto" width="auto"/>
//         </div>
//     </div>
//   );
// };

// export default Me;

const Me = () => {
  return (
    <section id="meSection" className="me--section">
      <div className="me--section--content--box">
        <div className="me--section-content">
          <p className="me--section--description">
            An amateur artist based in Somerset, I spend my 9-5s staring at a screen coding.
          </p>
        </div>
      </div>
      <div className="me--section--image">
        <img src={MyPhoto} alt="Me in Norway"></img>
      </div>
    </section>
  );
};

export default Me;
