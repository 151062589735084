import { useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';

import { wildlife } from '../images/wildlife';

// const images = require.context('../images/config.ts', true);
// const imageList = images.keys().map(image => images(image));

const Wildlife = () => {
    const [selectedImg, setSelectedImg] = useState({imgCaption:{img:"", subject: "", location: ""}, i:0});

    // window.addEventListener("keydown", event => {
    //     if(event.key === "ArrowRight"){
    //         if (i < wildlife.length - 1) {
    //         // next event
    //             setSelectedImg({img:require(`../images/${wildlife[i+1].full_size}`), i: i+1})
    //         }
    //     } else if(event.key === "ArrowLeft"){
    //         if (i > 0) {
    //         // prev event
    //             setSelectedImg({img:require(`../images/${wildlife[i-1].full_size}`), i:i-1})
    //         }
    //     }
    // })

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
        let i = selectedImg.i

        // the required distance between touchStart and touchEnd to be detected as a swipe
        const minSwipeDistance = 50 

        const onTouchStart = (event: any) => {
            setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
            setTouchStart(event.targetTouches[0].clientX)
        }

        const onTouchMove = (event: any) => setTouchEnd(event.targetTouches[0].clientX)

        const onTouchEnd = () => {
            if (!touchStart || !touchEnd) return
            const distance = touchStart - touchEnd
            const isRightSwipe = distance > minSwipeDistance
            const isLeftSwipe = distance < -minSwipeDistance
            if (isRightSwipe) {
                if (i < wildlife.length - 1) {
                    // next event
                    setSelectedImg({imgCaption:{img:require(`../images/${wildlife[i+1].full_size}`), subject: wildlife[i+1].subject, location: wildlife[i+1].location}, i: i+1})
                }
            }
            else if (isLeftSwipe) {
                if (i > 0) {
                    // prev event
                    setSelectedImg({imgCaption:{img:require(`../images/${wildlife[i-1].full_size}`), subject: wildlife[i-1].subject, location: wildlife[i-1].location}, i: i-1})
                }
            }
        }
        const backdropRef = useRef<any>(null)

        useEffect(() => {            
        function handler(event: any) {
            const div = backdropRef.current
    
            if (div) {
                if(event.key === "ArrowRight"){
                    if (i < wildlife.length - 1) {
                    // next event
                        setSelectedImg({imgCaption:{img:require(`../images/${wildlife[i+1].full_size}`), subject: wildlife[i+1].subject, location: wildlife[i+1].location}, i: i+1})
                    }
                } else if(event.key === "ArrowLeft"){
                    if (i > 0) {
                    // prev event
                        setSelectedImg({imgCaption:{img:require(`../images/${wildlife[i-1].full_size}`), subject: wildlife[i-1].subject, location: wildlife[i-1].location}, i: i-1})
                    }
                }
            }
        };
            window.addEventListener("keydown", handler);
            return () => {
                window.removeEventListener("keydown", handler);
            }
        }, [i])


    const handleClick = (e: any) => {
        if (e.target.classList.contains('backdrop')) {
            setSelectedImg({imgCaption:{img:"", subject: "", location: ""}, i:0});
            document.documentElement.style.overflowY="visible"
        }
    }


    return (
    <>
        <div className="img-grid">
            {wildlife.map((image, index) => (
                <motion.div className="img-wrap"
                layout
                whileHover={{ opacity: 1 }}
                
                onClick={() => { document.documentElement.style.overflowY="hidden"; setSelectedImg({imgCaption:{img:require(`../images/${wildlife[index].full_size}`), subject: wildlife[index].subject, location: wildlife[index].location}, i: index})}}
                >
                    <motion.img src={require( `../images/${image.full_size}`)} alt=""
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1 }}
                        />
                    
                </motion.div>
            ))}
        </div>

            {
                selectedImg.imgCaption.img &&
                    <motion.div className="backdrop" onClick={handleClick} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        >
                        <div className="img-caption">
                        <motion.img ref={backdropRef} src={selectedImg.imgCaption.img} alt="" />
                        <p><b>{selectedImg.imgCaption.subject}</b><br/>{selectedImg.imgCaption.location}</p>
                        </div>
                        
                    </motion.div>
            }
    </>
    )
};

export default Wildlife;
